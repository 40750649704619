.redemption {
    padding: 5em;
    padding-top: 100px;
    background: #140603;
    min-height: 100vh;
    padding-top: 220px;
    .redemption_container {
        max-width: 1000px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        gap: 30px;
        h1 {
            font-size: 56px;
            color: white;
            text-align: center;
        }
        p {
            font-size: 22px;
            color: #f7f7f7;
            text-align: center;
        }
        h4 {
            font-size: 26px;
            color: white;
            text-align: center;
        }
    }
}
@media (max-width: 768px) {
    .redemption {
        padding: 5em 20px; 
        padding-top: 100px;
        .redemption_container { 
            h1 {
                font-size: 36px; 
            }
            p {
                font-size: 16px;
                line-height: 1.3em;
            } 
        }
    }
}