header {
    background: black;
    width: 100%;
    padding: 0 60px;
    height: 102px;
    display: flex;
    justify-content: space-between;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10; 
    .link_logo {
        margin: auto;
        width: 123px;
        margin: auto 0;
        img {
            width: 100%;
        }
    }
    nav {
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(100% - 300px);
        height: 100%;
        small {
            color: rgba(255, 255, 255, 0.563);
            font-size: 11px;
            text-align: center;
        }
        a {
            color: white;
            font-size: 18px;
            width: 100%;
            height: 100%;
            line-height: 100%;
            text-align: center;
            border-bottom: 2px solid #444444;
            display: flex;
            align-items: center;
            transition: .3s;
            justify-content: center;
            &:hover {
                color: #800503;
                border-color: #800503;
            }
            &.cta_btn {
                background: #800201;
                color: white;
                font-size: 25px;
                padding: 10px 30px;
                min-height: 40px;
                margin: 0 auto;
                user-select: none;
                cursor: pointer;
            }
        }
    }
    .us_dollar {
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin: auto 0;
        align-items: center;
        span {
            color: white;
            &.up_arr {
                color: green;
                font-size: 12px;
            }
        }
        small {
            color: white;
            font-size: 12px;
        }
    }
    .img_icon {
        display: none;
    } 
}
@media (max-width: 768px) {
    header {
        background: black;
        width: 100%;
        padding: 20px; 
        position: relative;
        height: fit-content;
        .link_logo {
            width: 93px;
        }
        nav {
            position: absolute;
            top: 100%;
            width: 100%;
            left: 0;
            flex-direction: column;
            gap: 20px;
            height: fit-content;
            padding: 20px;
            background: rgba($color: #000000, $alpha: .8);
            transition: opacity 0.3s;
            opacity: 0; 
            pointer-events: none; 
            a {
                opacity: 0; 
                transition: opacity 0.3s;
                border-bottom: 0;
            } 
            &.opened {
                pointer-events: all;  
                opacity: 1; 
                a {
                    opacity: 1; 
                }  
                @for $i from 1 through 9 { 
                    a:nth-child(#{$i}) {
                        transition-delay: $i * 0.1s; 
                    }
                }
            }
        }
        .img_icon {
            width: 50px;
            display: block;
        } 
        .us_dollar {
            display: none;
        }
    }
}