.stellar {
    background: #140603;
    padding: 80px 20px;
    padding-top: 200px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    h2 {
        max-width: 1440px;
        margin: 0 auto;
        text-align: center;
        font-size: 50px;
        color: white;
    }
    .stellar_row_content {
        max-width: 1440px;
        margin: auto;
        display: grid;
        grid-template-columns: auto auto;
        grid-gap: 80px 24px;
        gap: 80px 24px;
        justify-content: center;
        align-items: center; 
        margin-bottom: 50px;
    }
    .row_txt {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 50px;
        margin-bottom: 60px;
        &:nth-child(2n) {
            flex-direction: row-reverse;
        }
        .full_width {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 20px;
            h3 {
                color: white;
                font-size: 32px; 
            }
            p {
                color: white;
                font-size: 16px;
                text-align: center;
            }
        }
        .txt_side {
            width: 50%;
            display: flex;
            flex-direction: column;
            gap: 20px;
            h3 {
                color: white;
                font-size: 32px; 
            }
            p {
                color: white;
                font-size: 16px;
            }
        }
        .img_side {
            max-width: 40%; 
            max-height: 450px;
            object-fit: contain;
        }
    }
    .stellar_row_content {
        display: flex;
        gap: 50px;
        align-items: center;  
        &.align_left {
            align-items: flex-start;
            margin-top: 40px;
            * {
                text-align: left !important;
            }
        }
        .txt_side {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 15px;
            h3 {
                color: white;
                font-size: 25px;
                text-align: center;
            }
            p {
                text-align: center;
                color: white;
            }
        }
        .stellar_img {
            width: 50%;
            img {
                width: 100%;
                aspect-ratio: 1 / 1;
                border-radius: 10px;
                object-fit: cover;
            }
        }
    }
}
@media (max-width: 768px) {
    .stellar { 
        padding: 20px;
        padding-top: 90px; 
        h2 { 
            font-size: 30px; 
        }
        .stellar_row_content {
            flex-direction: column;
        }
        .row_txt {
            width: 100%;
            flex-direction: column;
            padding-bottom: 60px;
            margin-bottom: 60px;
            gap: 20px;
            border-bottom: 1px solid rgb(77, 77, 77);
            &:nth-child(2n) {
                flex-direction: column;
            }
            .txt_side {
                width: 100%;
                h3 {
                    color: white;
                    font-size: 27px; 
                }
                p {
                    color: white;
                    font-size: 16px;
                }
            }
            .img_side {
                max-width: 100%;
            }
        }
        .stellar_row_content { 
            .txt_side {
                order: 2;
            }
            .stellar_img {
                order: 1;
                width: 100%; 
            }
        }
    }
}