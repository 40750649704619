footer {
    background: black;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px 10%;
    & > p {
        width: 100%;
        color: white;
        font-size: 16px;
    }
    .social_icons {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        gap: 100px;
        a {
            img {
                width: 30px;
            };
        }
    }
}
@media (max-width: 768px) {
    footer { 
        flex-direction: column;
        gap: 20px;
        & > p {
            text-align: center;
        }
        .social_icons { 
            display: flex;
            justify-content: space-between;
            gap: 0;
            a {
                img {
                    width: 30px;
                };
            }
        }
    }
}