/*------------------------|
|          FONTS          |
|------------------------*/
@font-face {
    font-family: "Sancoale Softened";
    src: url(../fonts/sancoale_softened/sancoale_softened_medium.ttf);
}
@font-face {
    font-family: "Inter";
    src: url(../fonts/Inter/static/Inter-Regular.ttf);
}

/*------------------------|
|       TYPOGRAPHY        |
|------------------------*/
* {
    font-family: "Inter", sans-serif;
}
nav a {
    font-family: "Sancoale Softened", sans-serif;
}
h1,h2,h3,h4,h5,h6 {
    font-family: "Sancoale Softened", sans-serif;
}


/*------------------------|
|          PAGE           |
|------------------------*/
* { 
    margin: 0;
    padding: 0;
    box-sizing: border-box; 
    text-decoration: none;
    scroll-behavior: smooth;
}
@media (min-width: 768px) { 
    .hide_desktop {
        display: none;
    }
}