.home {
    background: url(../images/home_bg.png);
    background-size: contain;
    background-color: #090301;
    background-repeat: no-repeat;
    .hero {
        padding: 120px 100px;
        padding-top: 260px;
        h1 {
            font-size: 140px;
            color: white;
            max-width: 850px;
        }
        .hero_container {
            max-width: 1200px;
            margin: 0 auto;
            .hero_tabs {
                display: flex;
                margin-top: 50px;
                margin-bottom: 50px; 
                .tab_buttons_container {
                    display: flex;
                    flex-direction: column;
                    align-items: center; 
                    width: 75%;
                    gap: 20px;
                    .tab_button {
                        border: 1px solid #f7f7f7;
                        padding: 10px 12px;
                        background: transparent;
                        color: #f7f7f7!important;
                        border-radius: 23px;
                        box-shadow: inset 0 3px 6px rgba(0,0,0,.82);
                        font-size: 20px;
                        display: flex;
                        gap: 8px;
                        justify-content: center;
                        align-items: center;
                        transition: .3s;
                        cursor: pointer;
                        &:hover {
                            background: #a9332f;
                            color: white;
                        }
                    }
                }
                .tabs_content_container {
                    border-left: 2px solid #454545; 
                    width: 100%;
                    padding: 30px 0;
                    padding-left: 20px;
                    background: #000000;
                    p {
                        display: flex;
                        flex-direction: column;
                        gap: 20px;
                        color: white;
                        text-align: center;  
                    }
                    .content_container {
                        display: flex;
                        flex-direction: column;
                        gap: 20px;
                        position: relative; 
                        img {
                            width: 200px;
                            max-width: 100%;
                            margin: 0 auto;
                        }
                    }
                } 
            }
        }
    }
    .true_value {
        max-width: 1200px;
        margin: 0 auto;
        padding: 40px 40px 24px;
        border: 2px solid #a9332f;
        border-radius: 25px;
        h2 {
            text-align: center;
            color: white;
            font-size: 74px;
            margin-bottom: 40px;
        }
        p {
            max-width: 687px;
            text-align: center;
            color: #fff;
            margin: auto auto 40px;
        }
        .true_value_container {
            display: flex;
            gap: 50px;
            .left_side {
                width: 70%;
                display: flex;
                flex-direction: column;
                gap: 90px;
                .chart_card_container {
                    .chart_title {
                        color: white;
                        font-size: 15px;
                    }
                    img {
                        width: 100%;
                    }
                    .horisontal_chart_content {
                        display: flex;
                        flex-direction: column;
                        gap: 7px;
                        & > div {
                            display: flex; 
                            gap: 10px;
                            & > div {
                                &:nth-child(1) {
                                    width:40px;
                                    color: white;
                                    font-size: 14px;
                                } 
                                &:nth-child(2) {
                                    color: white;
                                    width: calc(100% - 80px);
                                    & > div {
                                        border: 1px solid #800503;
                                        height: 15px;
                                        border-radius: 50px;
                                    }
                                }
                                &:nth-child(3) {
                                    color: white;
                                    width:40px;
                                    text-align: right;
                                    font-size: 12px;
                                    &.small_size_text {
                                        font-size: 9px;
                                        white-space: nowrap;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .middle_side {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 25px;
                .middle_rocks {
                    width: 100%;
                }
                .us_dollar {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    margin: auto 0;
                    align-items: center;
                    span {
                        color: white;
                        font-size: 23px;
                        &.up_arr {
                            color: green;
                            font-size: 12px;
                        }
                    }
                    small {
                        color: white;
                        font-size: 12px;
                    }
                }
            }
            .right_side {
                width: 60%;
                display: flex;
                align-items: center;
                justify-content: center;
                @keyframes zlayqi {
                    0% {
                        opacity: 0;
                        transform: translateX(-500px) translateY(0) rotateY(0);
                    }
                    100% {
                        opacity: 1;
                        transform: translateX(0) translateY(0) rotate(1turn);
                    }
                }
                @keyframes bounce {
                    0% {
                        transform: translateY(0);
                    } 
                    25% {
                        transform: translateY(10%);
                    }
                    50% {
                        transform: translateY(0);
                    }
                    75% {
                        transform: translateY(-10%);
                    }
                    100% {
                        transform: translateY(0);
                    }
                }
                .moneda {
                    animation: bounce 5s infinite, zlayqi 4s;
                    width: 226px;
                }
            }
        }
    }
    .our_team {
        overflow: hidden;
        margin: 100px 0;
        padding: 50px;
        display: flex;
        flex-direction: column;
        gap: 30px;
        h2 {
            font-size: 74px;
            text-align: center;
            color: #ffffff;
        }
        .team_slider {
            .swiper-slide {
                border: 2px solid #800503;
                border-radius: 30px;
                padding: 20px;
                background: #800503;
                display: flex;
                flex-direction: column;
                gap: 10px;
                img {
                    width: 100%;
                    height: 350px;
                    border-radius: 20px;
                    margin-bottom: 20px;
                    aspect-ratio: 1 / 1.3;
                    object-fit: cover;
                    filter: grayscale(100%);
                    object-position: top center;
                }
                .team_name,
                .team_role {
                    text-align: center;
                    color: white;
                }
                .team_name {
                    font-weight: 600;
                    font-size: 18px;
                }
            }
            .swiper-button-prev,
            .swiper-button-next {
                background: white;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                font-size: 18px;
                padding: 20px;
                &::after {
                    font-size: 20px;
                    color: #800503;
                }
            }
        }
    }
    .evolution {
        overflow: hidden;
        position: relative;
        width: 100%;
        height: 700px;
        @keyframes fade_up_down {
            0% {
                transform: scale(1) translateX(0);
            }
            50% {
                transform: scale(1.1) translateX(10px);
            }
            100% {
                transform: scale(1) translateX(0);
            }
        }
        .banner_man {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            z-index: 0;
            object-fit: cover;
            animation: fade_up_down 20s ease-in-out infinite;
        }
        p {
            color: white;
            font-size: 25px;
            max-width: 540px;
            position: absolute;
            transform: translateY(-50%);
            top: 50%;
            left: 10%;
            text-align: center;
        }
    }
    .what_is_bcoal {
        position: relative;
        padding: 100px 20px;
        background: #090606;
        .bcoal_container {
            max-width: 1200px;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            h2 {
                background-color: #fff;
                border: 1px solid #a9332f;
                color: #a9332f;
                border-radius: 24px;
                font-family: "Sancoale Softened", sans-serif;
                font-size: 37px;
                left: 50%; 
                padding: 10px 40px;
                margin: 0 auto;
                transform: translateY(50%);
                z-index: 1;
            }
            .bcoal_features_container {
                border: 2px solid #800503;
                padding: 80px 40px 44px;
                border-radius: 16px;
                display: flex;
                justify-content: space-between;
                .bcoal_feature {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    h3 {
                        color: white;
                        text-align: center;
                        font-size: 83px;
                        small {
                            font-size: .3em;
                        }
                    }
                    p {
                        color: white;
                        font-size: 15px;
                        text-align: center;
                    }
                }
            }
            & > p {
                width: 100%;
                max-width: 800px;
                padding: 100px 20px;
                padding-bottom: 20px;
                font-size: 20px;
                color: white;
                margin: 0 auto;
                text-align: center;
                span {
                    color: #a9332f;
                }
            }
        }
    }
    .about_technology {
        background: url(../images/about.webp);
        background-size: cover;
        background-position: center center;
        .about_us, .technology {
            max-width: 1440px;
            margin: 0 auto;
            padding: 80px 20px;
        }
    }
    .about_us {
        h2 {
            text-align: left;
            color: white;
            font-size: 110px;
        }
        p {
            font-size: 16px;
            a {
                color: #a9332f;
                line-height: 1.6em;
            }
        }
        .tech_paragraph {
            margin-top: 30px;
            display: flex;
            flex-direction: column;
            gap: 30px;
            p {
                color: white;
                text-align: center;
                font-size: 22px;
            }
        }
    }
    .technology {
        display: flex;
        align-items: center;
        gap: 50px;
        .img_side {
            width: 100%;
            img {
                width: 100%;
            }
        }
        .technology_text {
            width: 100%;
            h2 {
                text-align: left;
                color: white;
                font-size: 110px;
            }
            .txt_container {
                display: flex;
                flex-direction: column;
                gap: 20px;
                padding: 20px 40px;
                margin-top: 50px;
                border-left: 2px solid #a9a9a9;
                p {
                    color: #f7f7f7;
                    text-align: center;
                    font-size: 18px;
                }
            }
        }
    }
    #whatisbcoal,
    #ourteam,
    #aboutus {
        height: 120px;
    }
}
@media(max-width: 768px) {
    .home { 
        padding: 0 20px;
        overflow-x: hidden;
        .hero {
            padding: 50px 0;
            padding-top: 60px;
            h1 {
                font-size: 50px; 
                text-align: center;
            }
            .hero_container {
                flex-direction: column;
                .hero_tabs {
                    flex-direction: column;
                    gap: 20px;
                    .tab_buttons_container { 
                        width: 100%; 
                        .tab_button {
                            font-size: 14px;
                            width: 100%; 
                        }
                    }
                    .tabs_content_container {
                        background: #000000; 
                    } 
                }
            }
        }
        .true_value {
            background: #090301;
            padding: 20px;
            h2 {
                font-size: 30px;
                margin-bottom: 20px;
            }
            p { 
                margin: unset;
                margin-bottom: 20px;
            }
            .true_value_container {
                flex-direction: column;
                .left_side {
                    width: 100%; 
                    order: 2;
                    .chart_card_container {
                        &:first-child {
                            display: none;
                        } 
                    }
                }
                .middle_side {
                    order: 1; 
                    .middle_rocks {
                        display: none;
                    }
                }
                .right_side {
                    width: 100%;
                    order: 3;
                    .moneda { 
                        width: 126px;
                    }
                }
            }
        }
        .our_team {
            padding: 20px;
            overflow: visible;
            h2 {
                font-size: 40px;
            }
            .team_slider {
                .swiper { 
                    overflow: visible;
                } 
            }
        }
        .evolution { 
            height: 350px;
            width: calc(100% + 40px);
            margin-left: -20px;
            @keyframes fade_up_down {
                0% {
                    transform: scale(1) translateX(0);
                }
                50% {
                    transform: scale(1.1) translateX(10px);
                }
                100% {
                    transform: scale(1) translateX(0);
                }
            }
            .banner_man {
                left: unset;
                right: 0;
                object-position: right;
            }
            p {
                line-height: 1.2em;
                font-size: 14px; 
                margin-top: 30px;
            }
        }
        .what_is_bcoal {
            padding: 20px 20px;
            .bcoal_container { 
                h2 {
                    font-size: 20px;
                }
                .bcoal_features_container {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    padding: 30px 20px;
                    grid-row-gap: 30px;
                    .bcoal_feature { 
                        h3 { 
                            font-size: 45px; 
                        }
                        p { 
                            font-size: 12px; 
                        }
                    }
                }
                & > p { 
                    padding: 20px 0; 
                    font-size: 14px; 
                    line-height: 1.3em;
                }
            }
        }
        .about_technology {
            width: calc(100% + 40px);
            margin-left: -20px;
            .about_us, .technology { 
                padding: 30px 20px;
            }
        }
        .about_us {
            h2 {
                font-size: 60px;
            }
            .tech_paragraph {
                margin-top: 20px; 
                gap: 15px;
                p { 
                    font-size: 18px;
                    font-weight: 600;
                }
            }
        }
        .technology {
            flex-direction: column; 
            .technology_text { 
                h2 { 
                    font-size: 50px;
                }
                .txt_container {   
                    padding-left: 15px;
                    margin-top: 20px;
                    p { 
                        font-size: 14px;
                    }
                }
            }
        }
    }
}