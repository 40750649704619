/*------------------------|
|          IMPORTS        |
|------------------------*/
/*------------------------|
|          FONTS          |
|------------------------*/
@font-face {
  font-family: "Sancoale Softened";
  src: url(../fonts/sancoale_softened/sancoale_softened_medium.ttf);
}
@font-face {
  font-family: "Inter";
  src: url(../fonts/Inter/static/Inter-Regular.ttf);
}
/*------------------------|
|       TYPOGRAPHY        |
|------------------------*/
* {
  font-family: "Inter", sans-serif;
}

nav a {
  font-family: "Sancoale Softened", sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Sancoale Softened", sans-serif;
}

/*------------------------|
|          PAGE           |
|------------------------*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  scroll-behavior: smooth;
}

@media (min-width: 768px) {
  .hide_desktop {
    display: none;
  }
}
header {
  background: black;
  width: 100%;
  padding: 0 60px;
  height: 102px;
  display: flex;
  justify-content: space-between;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
}
header .link_logo {
  margin: auto;
  width: 123px;
  margin: auto 0;
}
header .link_logo img {
  width: 100%;
}
header nav {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 300px);
  height: 100%;
}
header nav small {
  color: rgba(255, 255, 255, 0.563);
  font-size: 11px;
  text-align: center;
}
header nav a {
  color: white;
  font-size: 18px;
  width: 100%;
  height: 100%;
  line-height: 100%;
  text-align: center;
  border-bottom: 2px solid #444444;
  display: flex;
  align-items: center;
  transition: 0.3s;
  justify-content: center;
}
header nav a:hover {
  color: #800503;
  border-color: #800503;
}
header nav a.cta_btn {
  background: #800201;
  color: white;
  font-size: 25px;
  padding: 10px 30px;
  min-height: 40px;
  margin: 0 auto;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  cursor: pointer;
}
header .us_dollar {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: auto 0;
  align-items: center;
}
header .us_dollar span {
  color: white;
}
header .us_dollar span.up_arr {
  color: green;
  font-size: 12px;
}
header .us_dollar small {
  color: white;
  font-size: 12px;
}
header .img_icon {
  display: none;
}

@media (max-width: 768px) {
  header {
    background: black;
    width: 100%;
    padding: 20px;
    position: relative;
    height: -moz-fit-content;
    height: fit-content;
  }
  header .link_logo {
    width: 93px;
  }
  header nav {
    position: absolute;
    top: 100%;
    width: 100%;
    left: 0;
    flex-direction: column;
    gap: 20px;
    height: -moz-fit-content;
    height: fit-content;
    padding: 20px;
    background: rgba(0, 0, 0, 0.8);
    transition: opacity 0.3s;
    opacity: 0;
    pointer-events: none;
  }
  header nav a {
    opacity: 0;
    transition: opacity 0.3s;
    border-bottom: 0;
  }
  header nav.opened {
    pointer-events: all;
    opacity: 1;
  }
  header nav.opened a {
    opacity: 1;
  }
  header nav.opened a:nth-child(1) {
    transition-delay: 0.1s;
  }
  header nav.opened a:nth-child(2) {
    transition-delay: 0.2s;
  }
  header nav.opened a:nth-child(3) {
    transition-delay: 0.3s;
  }
  header nav.opened a:nth-child(4) {
    transition-delay: 0.4s;
  }
  header nav.opened a:nth-child(5) {
    transition-delay: 0.5s;
  }
  header nav.opened a:nth-child(6) {
    transition-delay: 0.6s;
  }
  header nav.opened a:nth-child(7) {
    transition-delay: 0.7s;
  }
  header nav.opened a:nth-child(8) {
    transition-delay: 0.8s;
  }
  header nav.opened a:nth-child(9) {
    transition-delay: 0.9s;
  }
  header .img_icon {
    width: 50px;
    display: block;
  }
  header .us_dollar {
    display: none;
  }
}
footer {
  background: black;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px 10%;
}
footer > p {
  width: 100%;
  color: white;
  font-size: 16px;
}
footer .social_icons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 100px;
}
footer .social_icons a img {
  width: 30px;
}

@media (max-width: 768px) {
  footer {
    flex-direction: column;
    gap: 20px;
  }
  footer > p {
    text-align: center;
  }
  footer .social_icons {
    display: flex;
    justify-content: space-between;
    gap: 0;
  }
  footer .social_icons a img {
    width: 30px;
  }
}
.copy-button {
  justify-self: center;
  margin: 0 auto;
  width: -moz-fit-content;
  width: fit-content;
  background: black;
  border: 1px solid white;
  padding: 10px 20px;
  line-height: 1em;
  height: auto;
  padding-right: 40px;
}
.copy-button:hover {
  background-color: black !important;
}
.copy-button .ant-btn-icon {
  position: absolute;
  right: 4px;
  top: 4px;
  border: 1px solid white;
  padding: 2px;
  margin-inline-end: 0 !important;
  border-radius: 4px;
}

@media (max-width: 768px) {
  .copy-button {
    font-size: 11px !important;
    padding-right: 30px;
    padding-left: 10px;
  }
  .copy-button .ant-btn-icon {
    position: absolute;
    right: 4px;
    top: 4px;
    border: 1px solid white;
    padding: 2px;
    margin-inline-end: 0 !important;
    border-radius: 4px;
  }
}
.home {
  background: url(../images/home_bg.png);
  background-size: contain;
  background-color: #090301;
  background-repeat: no-repeat;
}
.home .hero {
  padding: 120px 100px;
  padding-top: 260px;
}
.home .hero h1 {
  font-size: 140px;
  color: white;
  max-width: 850px;
}
.home .hero .hero_container {
  max-width: 1200px;
  margin: 0 auto;
}
.home .hero .hero_container .hero_tabs {
  display: flex;
  margin-top: 50px;
  margin-bottom: 50px;
}
.home .hero .hero_container .hero_tabs .tab_buttons_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75%;
  gap: 20px;
}
.home .hero .hero_container .hero_tabs .tab_buttons_container .tab_button {
  border: 1px solid #f7f7f7;
  padding: 10px 12px;
  background: transparent;
  color: #f7f7f7 !important;
  border-radius: 23px;
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.82);
  font-size: 20px;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  cursor: pointer;
}
.home .hero .hero_container .hero_tabs .tab_buttons_container .tab_button:hover {
  background: #a9332f;
  color: white;
}
.home .hero .hero_container .hero_tabs .tabs_content_container {
  border-left: 2px solid #454545;
  width: 100%;
  padding: 30px 0;
  padding-left: 20px;
  background: #000000;
}
.home .hero .hero_container .hero_tabs .tabs_content_container p {
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: white;
  text-align: center;
}
.home .hero .hero_container .hero_tabs .tabs_content_container .content_container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
}
.home .hero .hero_container .hero_tabs .tabs_content_container .content_container img {
  width: 200px;
  max-width: 100%;
  margin: 0 auto;
}
.home .true_value {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 40px 24px;
  border: 2px solid #a9332f;
  border-radius: 25px;
}
.home .true_value h2 {
  text-align: center;
  color: white;
  font-size: 74px;
  margin-bottom: 40px;
}
.home .true_value p {
  max-width: 687px;
  text-align: center;
  color: #fff;
  margin: auto auto 40px;
}
.home .true_value .true_value_container {
  display: flex;
  gap: 50px;
}
.home .true_value .true_value_container .left_side {
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 90px;
}
.home .true_value .true_value_container .left_side .chart_card_container .chart_title {
  color: white;
  font-size: 15px;
}
.home .true_value .true_value_container .left_side .chart_card_container img {
  width: 100%;
}
.home .true_value .true_value_container .left_side .chart_card_container .horisontal_chart_content {
  display: flex;
  flex-direction: column;
  gap: 7px;
}
.home .true_value .true_value_container .left_side .chart_card_container .horisontal_chart_content > div {
  display: flex;
  gap: 10px;
}
.home .true_value .true_value_container .left_side .chart_card_container .horisontal_chart_content > div > div:nth-child(1) {
  width: 40px;
  color: white;
  font-size: 14px;
}
.home .true_value .true_value_container .left_side .chart_card_container .horisontal_chart_content > div > div:nth-child(2) {
  color: white;
  width: calc(100% - 80px);
}
.home .true_value .true_value_container .left_side .chart_card_container .horisontal_chart_content > div > div:nth-child(2) > div {
  border: 1px solid #800503;
  height: 15px;
  border-radius: 50px;
}
.home .true_value .true_value_container .left_side .chart_card_container .horisontal_chart_content > div > div:nth-child(3) {
  color: white;
  width: 40px;
  text-align: right;
  font-size: 12px;
}
.home .true_value .true_value_container .left_side .chart_card_container .horisontal_chart_content > div > div:nth-child(3).small_size_text {
  font-size: 9px;
  white-space: nowrap;
}
.home .true_value .true_value_container .middle_side {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
}
.home .true_value .true_value_container .middle_side .middle_rocks {
  width: 100%;
}
.home .true_value .true_value_container .middle_side .us_dollar {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: auto 0;
  align-items: center;
}
.home .true_value .true_value_container .middle_side .us_dollar span {
  color: white;
  font-size: 23px;
}
.home .true_value .true_value_container .middle_side .us_dollar span.up_arr {
  color: green;
  font-size: 12px;
}
.home .true_value .true_value_container .middle_side .us_dollar small {
  color: white;
  font-size: 12px;
}
.home .true_value .true_value_container .right_side {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@keyframes zlayqi {
  0% {
    opacity: 0;
    transform: translateX(-500px) translateY(0) rotateY(0);
  }
  100% {
    opacity: 1;
    transform: translateX(0) translateY(0) rotate(1turn);
  }
}
@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(10%);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(-10%);
  }
  100% {
    transform: translateY(0);
  }
}
.home .true_value .true_value_container .right_side .moneda {
  animation: bounce 5s infinite, zlayqi 4s;
  width: 226px;
}
.home .our_team {
  overflow: hidden;
  margin: 100px 0;
  padding: 50px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.home .our_team h2 {
  font-size: 74px;
  text-align: center;
  color: #ffffff;
}
.home .our_team .team_slider .swiper-slide {
  border: 2px solid #800503;
  border-radius: 30px;
  padding: 20px;
  background: #800503;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.home .our_team .team_slider .swiper-slide img {
  width: 100%;
  height: 350px;
  border-radius: 20px;
  margin-bottom: 20px;
  aspect-ratio: 1/1.3;
  -o-object-fit: cover;
     object-fit: cover;
  filter: grayscale(100%);
  -o-object-position: top center;
     object-position: top center;
}
.home .our_team .team_slider .swiper-slide .team_name,
.home .our_team .team_slider .swiper-slide .team_role {
  text-align: center;
  color: white;
}
.home .our_team .team_slider .swiper-slide .team_name {
  font-weight: 600;
  font-size: 18px;
}
.home .our_team .team_slider .swiper-button-prev,
.home .our_team .team_slider .swiper-button-next {
  background: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  font-size: 18px;
  padding: 20px;
}
.home .our_team .team_slider .swiper-button-prev::after,
.home .our_team .team_slider .swiper-button-next::after {
  font-size: 20px;
  color: #800503;
}
.home .evolution {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 700px;
}
@keyframes fade_up_down {
  0% {
    transform: scale(1) translateX(0);
  }
  50% {
    transform: scale(1.1) translateX(10px);
  }
  100% {
    transform: scale(1) translateX(0);
  }
}
.home .evolution .banner_man {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 0;
  -o-object-fit: cover;
     object-fit: cover;
  animation: fade_up_down 20s ease-in-out infinite;
}
.home .evolution p {
  color: white;
  font-size: 25px;
  max-width: 540px;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  left: 10%;
  text-align: center;
}
.home .what_is_bcoal {
  position: relative;
  padding: 100px 20px;
  background: #090606;
}
.home .what_is_bcoal .bcoal_container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.home .what_is_bcoal .bcoal_container h2 {
  background-color: #fff;
  border: 1px solid #a9332f;
  color: #a9332f;
  border-radius: 24px;
  font-family: "Sancoale Softened", sans-serif;
  font-size: 37px;
  left: 50%;
  padding: 10px 40px;
  margin: 0 auto;
  transform: translateY(50%);
  z-index: 1;
}
.home .what_is_bcoal .bcoal_container .bcoal_features_container {
  border: 2px solid #800503;
  padding: 80px 40px 44px;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
}
.home .what_is_bcoal .bcoal_container .bcoal_features_container .bcoal_feature {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.home .what_is_bcoal .bcoal_container .bcoal_features_container .bcoal_feature h3 {
  color: white;
  text-align: center;
  font-size: 83px;
}
.home .what_is_bcoal .bcoal_container .bcoal_features_container .bcoal_feature h3 small {
  font-size: 0.3em;
}
.home .what_is_bcoal .bcoal_container .bcoal_features_container .bcoal_feature p {
  color: white;
  font-size: 15px;
  text-align: center;
}
.home .what_is_bcoal .bcoal_container > p {
  width: 100%;
  max-width: 800px;
  padding: 100px 20px;
  padding-bottom: 20px;
  font-size: 20px;
  color: white;
  margin: 0 auto;
  text-align: center;
}
.home .what_is_bcoal .bcoal_container > p span {
  color: #a9332f;
}
.home .about_technology {
  background: url(../images/about.webp);
  background-size: cover;
  background-position: center center;
}
.home .about_technology .about_us, .home .about_technology .technology {
  max-width: 1440px;
  margin: 0 auto;
  padding: 80px 20px;
}
.home .about_us h2 {
  text-align: left;
  color: white;
  font-size: 110px;
}
.home .about_us p {
  font-size: 16px;
}
.home .about_us p a {
  color: #a9332f;
  line-height: 1.6em;
}
.home .about_us .tech_paragraph {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.home .about_us .tech_paragraph p {
  color: white;
  text-align: center;
  font-size: 22px;
}
.home .technology {
  display: flex;
  align-items: center;
  gap: 50px;
}
.home .technology .img_side {
  width: 100%;
}
.home .technology .img_side img {
  width: 100%;
}
.home .technology .technology_text {
  width: 100%;
}
.home .technology .technology_text h2 {
  text-align: left;
  color: white;
  font-size: 110px;
}
.home .technology .technology_text .txt_container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 40px;
  margin-top: 50px;
  border-left: 2px solid #a9a9a9;
}
.home .technology .technology_text .txt_container p {
  color: #f7f7f7;
  text-align: center;
  font-size: 18px;
}
.home #whatisbcoal,
.home #ourteam,
.home #aboutus {
  height: 120px;
}

@media (max-width: 768px) {
  .home {
    padding: 0 20px;
    overflow-x: hidden;
  }
  .home .hero {
    padding: 50px 0;
    padding-top: 60px;
  }
  .home .hero h1 {
    font-size: 50px;
    text-align: center;
  }
  .home .hero .hero_container {
    flex-direction: column;
  }
  .home .hero .hero_container .hero_tabs {
    flex-direction: column;
    gap: 20px;
  }
  .home .hero .hero_container .hero_tabs .tab_buttons_container {
    width: 100%;
  }
  .home .hero .hero_container .hero_tabs .tab_buttons_container .tab_button {
    font-size: 14px;
    width: 100%;
  }
  .home .hero .hero_container .hero_tabs .tabs_content_container {
    background: #000000;
  }
  .home .true_value {
    background: #090301;
    padding: 20px;
  }
  .home .true_value h2 {
    font-size: 30px;
    margin-bottom: 20px;
  }
  .home .true_value p {
    margin: unset;
    margin-bottom: 20px;
  }
  .home .true_value .true_value_container {
    flex-direction: column;
  }
  .home .true_value .true_value_container .left_side {
    width: 100%;
    order: 2;
  }
  .home .true_value .true_value_container .left_side .chart_card_container:first-child {
    display: none;
  }
  .home .true_value .true_value_container .middle_side {
    order: 1;
  }
  .home .true_value .true_value_container .middle_side .middle_rocks {
    display: none;
  }
  .home .true_value .true_value_container .right_side {
    width: 100%;
    order: 3;
  }
  .home .true_value .true_value_container .right_side .moneda {
    width: 126px;
  }
  .home .our_team {
    padding: 20px;
    overflow: visible;
  }
  .home .our_team h2 {
    font-size: 40px;
  }
  .home .our_team .team_slider .swiper {
    overflow: visible;
  }
  .home .evolution {
    height: 350px;
    width: calc(100% + 40px);
    margin-left: -20px;
  }
  @keyframes fade_up_down {
    0% {
      transform: scale(1) translateX(0);
    }
    50% {
      transform: scale(1.1) translateX(10px);
    }
    100% {
      transform: scale(1) translateX(0);
    }
  }
  .home .evolution .banner_man {
    left: unset;
    right: 0;
    -o-object-position: right;
       object-position: right;
  }
  .home .evolution p {
    line-height: 1.2em;
    font-size: 14px;
    margin-top: 30px;
  }
  .home .what_is_bcoal {
    padding: 20px 20px;
  }
  .home .what_is_bcoal .bcoal_container h2 {
    font-size: 20px;
  }
  .home .what_is_bcoal .bcoal_container .bcoal_features_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 30px 20px;
    grid-row-gap: 30px;
  }
  .home .what_is_bcoal .bcoal_container .bcoal_features_container .bcoal_feature h3 {
    font-size: 45px;
  }
  .home .what_is_bcoal .bcoal_container .bcoal_features_container .bcoal_feature p {
    font-size: 12px;
  }
  .home .what_is_bcoal .bcoal_container > p {
    padding: 20px 0;
    font-size: 14px;
    line-height: 1.3em;
  }
  .home .about_technology {
    width: calc(100% + 40px);
    margin-left: -20px;
  }
  .home .about_technology .about_us, .home .about_technology .technology {
    padding: 30px 20px;
  }
  .home .about_us h2 {
    font-size: 60px;
  }
  .home .about_us .tech_paragraph {
    margin-top: 20px;
    gap: 15px;
  }
  .home .about_us .tech_paragraph p {
    font-size: 18px;
    font-weight: 600;
  }
  .home .technology {
    flex-direction: column;
  }
  .home .technology .technology_text h2 {
    font-size: 50px;
  }
  .home .technology .technology_text .txt_container {
    padding-left: 15px;
    margin-top: 20px;
  }
  .home .technology .technology_text .txt_container p {
    font-size: 14px;
  }
}
.redemption {
  padding: 5em;
  padding-top: 100px;
  background: #140603;
  min-height: 100vh;
  padding-top: 220px;
}
.redemption .redemption_container {
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.redemption .redemption_container h1 {
  font-size: 56px;
  color: white;
  text-align: center;
}
.redemption .redemption_container p {
  font-size: 22px;
  color: #f7f7f7;
  text-align: center;
}
.redemption .redemption_container h4 {
  font-size: 26px;
  color: white;
  text-align: center;
}

@media (max-width: 768px) {
  .redemption {
    padding: 5em 20px;
    padding-top: 100px;
  }
  .redemption .redemption_container h1 {
    font-size: 36px;
  }
  .redemption .redemption_container p {
    font-size: 16px;
    line-height: 1.3em;
  }
}
.how_to_buy {
  background: #140603;
  padding-top: 200px;
}
.how_to_buy .how_to_buy_container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.how_to_buy .how_to_buy_container h1 {
  text-align: center;
  color: white;
  font-size: 32px;
  margin-bottom: 42px;
}
.how_to_buy .how_to_buy_container > p {
  color: white;
  font-size: 16px;
  margin-bottom: 20px;
  text-align: center;
}
.how_to_buy .how_to_buy_container .how_to_buy_image {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}
.how_to_buy .how_to_buy_container h2 {
  text-align: center;
  color: white;
  font-size: 40px;
  margin-bottom: 55px;
  margin-top: 42px;
}
.how_to_buy .how_to_buy_container .wallet_cards {
  display: flex;
  align-items: stretch;
  gap: 30px;
}
.how_to_buy .how_to_buy_container .wallet_cards .single_card {
  padding: 35px;
  background: #800201;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.how_to_buy .how_to_buy_container .wallet_cards .single_card h3 {
  font-size: 30px;
  color: white;
  text-align: center;
}
.how_to_buy .how_to_buy_container .wallet_cards .single_card p {
  font-size: 16px;
  color: white;
  text-align: center;
}
.how_to_buy .how_to_buy_container a {
  color: #b60000;
}
.how_to_buy .how_to_buy_container .row_txt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 50px;
  margin-bottom: 60px;
}
.how_to_buy .how_to_buy_container .row_txt:nth-child(2n) {
  flex-direction: row-reverse;
}
.how_to_buy .how_to_buy_container .row_txt .full_width {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.how_to_buy .how_to_buy_container .row_txt .full_width h3 {
  color: white;
  font-size: 32px;
}
.how_to_buy .how_to_buy_container .row_txt .full_width p {
  color: white;
  font-size: 16px;
  text-align: center;
}
.how_to_buy .how_to_buy_container .row_txt .txt_side {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.how_to_buy .how_to_buy_container .row_txt .txt_side h3 {
  color: white;
  font-size: 32px;
}
.how_to_buy .how_to_buy_container .row_txt .txt_side p {
  color: white;
  font-size: 16px;
}
.how_to_buy .how_to_buy_container .row_txt .img_side {
  max-width: 40%;
  max-height: 450px;
  -o-object-fit: contain;
     object-fit: contain;
}
.how_to_buy .how_to_buy_container hr {
  border-color: rgba(255, 255, 255, 0.069);
}
.how_to_buy .how_to_buy_container .cta_btn {
  background: #800201;
  color: white;
  font-size: 25px;
  padding: 10px 30px;
  margin: 0 auto;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  cursor: pointer;
}

.youtube_banner {
  background: #800503;
  padding: 60px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-direction: column;
  gap: 30px;
}
.youtube_banner h2 {
  font-size: 40px;
  color: white;
  max-width: 700px;
  text-align: center;
}
.youtube_banner p {
  font-size: 18px;
  color: white;
  max-width: 700px;
}

@media (max-width: 768px) {
  .how_to_buy {
    padding-top: 60px;
  }
  .how_to_buy .how_to_buy_container {
    padding: 0 20px;
  }
  .how_to_buy .how_to_buy_container h1 {
    font-size: 32px;
  }
  .how_to_buy .how_to_buy_container h2 {
    font-size: 25px;
  }
  .how_to_buy .how_to_buy_container .wallet_cards {
    flex-direction: column;
  }
  .how_to_buy .how_to_buy_container .row_txt {
    width: 100%;
    flex-direction: column;
    padding-bottom: 60px;
    margin-bottom: 60px;
    gap: 20px;
    border-bottom: 1px solid rgb(77, 77, 77);
  }
  .how_to_buy .how_to_buy_container .row_txt:nth-child(2n) {
    flex-direction: column;
  }
  .how_to_buy .how_to_buy_container .row_txt .txt_side {
    width: 100%;
  }
  .how_to_buy .how_to_buy_container .row_txt .txt_side h3 {
    color: white;
    font-size: 27px;
  }
  .how_to_buy .how_to_buy_container .row_txt .txt_side p {
    color: white;
    font-size: 16px;
  }
  .how_to_buy .how_to_buy_container .row_txt .img_side {
    max-width: 100%;
  }
  .how_to_buy .how_to_buy_container .cta_btn {
    font-size: 14px;
    text-align: center;
  }
  .youtube_banner h2 {
    font-size: 30px;
  }
  .youtube_banner iframe {
    width: 100%;
    height: 250px;
  }
}
.stellar {
  background: #140603;
  padding: 80px 20px;
  padding-top: 200px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.stellar h2 {
  max-width: 1440px;
  margin: 0 auto;
  text-align: center;
  font-size: 50px;
  color: white;
}
.stellar .stellar_row_content {
  max-width: 1440px;
  margin: auto;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 80px 24px;
  gap: 80px 24px;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}
.stellar .row_txt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 50px;
  margin-bottom: 60px;
}
.stellar .row_txt:nth-child(2n) {
  flex-direction: row-reverse;
}
.stellar .row_txt .full_width {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.stellar .row_txt .full_width h3 {
  color: white;
  font-size: 32px;
}
.stellar .row_txt .full_width p {
  color: white;
  font-size: 16px;
  text-align: center;
}
.stellar .row_txt .txt_side {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.stellar .row_txt .txt_side h3 {
  color: white;
  font-size: 32px;
}
.stellar .row_txt .txt_side p {
  color: white;
  font-size: 16px;
}
.stellar .row_txt .img_side {
  max-width: 40%;
  max-height: 450px;
  -o-object-fit: contain;
     object-fit: contain;
}
.stellar .stellar_row_content {
  display: flex;
  gap: 50px;
  align-items: center;
}
.stellar .stellar_row_content.align_left {
  align-items: flex-start;
  margin-top: 40px;
}
.stellar .stellar_row_content.align_left * {
  text-align: left !important;
}
.stellar .stellar_row_content .txt_side {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.stellar .stellar_row_content .txt_side h3 {
  color: white;
  font-size: 25px;
  text-align: center;
}
.stellar .stellar_row_content .txt_side p {
  text-align: center;
  color: white;
}
.stellar .stellar_row_content .stellar_img {
  width: 50%;
}
.stellar .stellar_row_content .stellar_img img {
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 10px;
  -o-object-fit: cover;
     object-fit: cover;
}

@media (max-width: 768px) {
  .stellar {
    padding: 20px;
    padding-top: 90px;
  }
  .stellar h2 {
    font-size: 30px;
  }
  .stellar .stellar_row_content {
    flex-direction: column;
  }
  .stellar .row_txt {
    width: 100%;
    flex-direction: column;
    padding-bottom: 60px;
    margin-bottom: 60px;
    gap: 20px;
    border-bottom: 1px solid rgb(77, 77, 77);
  }
  .stellar .row_txt:nth-child(2n) {
    flex-direction: column;
  }
  .stellar .row_txt .txt_side {
    width: 100%;
  }
  .stellar .row_txt .txt_side h3 {
    color: white;
    font-size: 27px;
  }
  .stellar .row_txt .txt_side p {
    color: white;
    font-size: 16px;
  }
  .stellar .row_txt .img_side {
    max-width: 100%;
  }
  .stellar .stellar_row_content .txt_side {
    order: 2;
  }
  .stellar .stellar_row_content .stellar_img {
    order: 1;
    width: 100%;
  }
}/*# sourceMappingURL=main.css.map */