.how_to_buy {
    background: #140603;
    padding-top: 200px;
    .how_to_buy_container {
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        h1 {
            text-align: center;
            color: white;
            font-size: 32px;
            margin-bottom: 42px;
        }
        & > p {
            color: white;
            font-size: 16px;
            margin-bottom: 20px;
            text-align: center;
        }
        .how_to_buy_image {
            width: 100%;
            max-width: 800px;
            margin: 0 auto;
        }
        h2 {
            text-align: center;
            color: white;
            font-size: 40px;
            margin-bottom: 55px;
            margin-top: 42px;
        }
        .wallet_cards {
            display: flex;
            align-items: stretch;
            gap: 30px;
            .single_card {
                padding: 35px;
                background: #800201;
                display: flex;
                flex-direction: column;
                gap: 30px;
                h3 {
                    font-size: 30px;
                    color: white;
                    text-align: center;
                }
                p {
                    font-size: 16px;
                    color: white;
                    text-align: center;
                }
            }
        }
        a {
            color: #b60000;
        }
        .row_txt {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            gap: 50px;
            margin-bottom: 60px;
            &:nth-child(2n) {
                flex-direction: row-reverse;
            }
            .full_width {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 20px;
                h3 {
                    color: white;
                    font-size: 32px; 
                }
                p {
                    color: white;
                    font-size: 16px;
                    text-align: center;
                }
            }
            .txt_side {
                width: 50%;
                display: flex;
                flex-direction: column;
                gap: 20px;
                h3 {
                    color: white;
                    font-size: 32px; 
                }
                p {
                    color: white;
                    font-size: 16px;
                }
            }
            .img_side {
                max-width: 40%; 
                max-height: 450px;
                object-fit: contain;
            }
        }
        hr {
            border-color: rgba(255, 255, 255, 0.069);
        }
        .cta_btn {
            background: #800201;
            color: white;
            font-size: 25px;
            padding: 10px 30px;
            margin: 0 auto;
            user-select: none;
            cursor: pointer;
        }
    }
}
.youtube_banner {
    background: #800503;
    padding: 60px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    h2 {
        font-size: 40px;
        color: white;
        max-width: 700px;
        text-align: center;
    }
    p {
        font-size: 18px;
        color: white;
        max-width: 700px;
    }
}
@media (max-width: 768px) {
    .how_to_buy {
        padding-top: 60px;
        .how_to_buy_container { 
            padding: 0 20px;
            h1 { 
                font-size: 32px; 
            } 
            h2 { 
                font-size: 25px; 
            }
            .wallet_cards {
                flex-direction: column;
            }
            .row_txt {
                width: 100%;
                flex-direction: column;
                padding-bottom: 60px;
                margin-bottom: 60px;
                gap: 20px;
                border-bottom: 1px solid rgb(77, 77, 77);
                &:nth-child(2n) {
                    flex-direction: column;
                }
                .txt_side {
                    width: 100%;
                    h3 {
                        color: white;
                        font-size: 27px; 
                    }
                    p {
                        color: white;
                        font-size: 16px;
                    }
                }
                .img_side {
                    max-width: 100%;
                }
            }
            .cta_btn {
                font-size: 14px;
                text-align: center;
            }
        }
    }
    .youtube_banner { 
        h2 {
            font-size: 30px; 
        }
        iframe {
            width: 100%;
            height: 250px;
        }
    }
}