.copy-button {
    justify-self: center;
    margin: 0 auto;
    width: fit-content;
    background: black;
    border: 1px solid white; 
    padding: 10px 20px; 
    line-height: 1em;
    height: auto;
    padding-right: 40px; 
    &:hover {
        background-color: black !important;
    }
    .ant-btn-icon {
        position: absolute;
        right: 4px;
        top:  4px;
        border: 1px solid white;
        padding: 2px;
        margin-inline-end: 0 !important;
        border-radius: 4px;
    }
} 
@media (max-width: 768px) {
    .copy-button {
        font-size: 11px !important;
        padding-right: 30px;
        padding-left: 10px;
        .ant-btn-icon {
            position: absolute;
            right: 4px;
            top:  4px;
            border: 1px solid white;
            padding: 2px;
            margin-inline-end: 0 !important;
            border-radius: 4px;
        }
    } 
}